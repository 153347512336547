'use strict'

module.exports.DETAILS_REPEATER_ROLE = 'detailsRepeaterRole' // Internal runtime-only role for managing repeaters

module.exports.DETAILS_DATASET_ROLE = 'detailsDatasetRole' // Role for connecting to another dataset for filterByDataset

module.exports.FILTER_INPUT_ROLE = 'filterInputRole' // Role for connecting component value to a filter parameter

// Roles for connecting components
module.exports.TEXT_ROLE = 'textRole'
module.exports.IMAGE_ROLE = 'imageRole'
module.exports.TEXT_INPUT_ROLE = 'textInputRole'
module.exports.BUTTON_ROLE = 'siteButtonRole'
module.exports.ICON_BUTTON_ROLE = 'imageButtonRole'
module.exports.CHECKBOX_ROLE = 'checkboxRole'
module.exports.GALLERY_ROLE = 'galleryRole'
module.exports.MEDIA_GALLERY_ROLE = 'mediaGalleryRole'
module.exports.RATINGSDISPLAY_ROLE = 'ratingsDisplayRole'
module.exports.RATINGSINPUT_ROLE = 'ratingsInputRole'
module.exports.DROPDOWN_ROLE = 'dropdownRole'
module.exports.DROPDOWN_OPTIONS_ROLE = 'dropdownOptionsRole'
module.exports.TEXT_BOX_ROLE = 'textAreaRole'
module.exports.RICH_TEXT_BOX_ROLE = 'richTextBoxRole'
module.exports.GRID_ROLE = 'gridRole'
module.exports.DATEPICKER_ROLE = 'datepickerRole'
module.exports.RADIOGROUP_ROLE = 'radioGroupRole'
module.exports.UPLOAD_BUTTON_ROLE = 'uploadButtonRole'
module.exports.VIDEO_ROLE = 'videoRole'
module.exports.VIDEOPLAYER_ROLE = 'videoPlayerRole'
module.exports.VIDEOBOX_ROLE = 'videoBoxRole'
module.exports.MUSICPLAYER_ROLE = 'musicPlayerRole'
module.exports.REPEATER_ROLE = 'repeaterRole'
module.exports.COLUMN_ROLE = 'columnRole'
module.exports.PAGE_ROLE = 'pageRole'
module.exports.MEDIACONTAINER_ROLE = 'mediaContainerRole'
module.exports.PAGINATION_ROLE = 'paginationRole'
module.exports.TOGGLESWITCH_ROLE = 'toggleSwitchRole'
module.exports.SLIDER_ROLE = 'sliderRole'
module.exports.ADDRESSINPUT_ROLE = 'addressInputRole'
module.exports.GOOGLEMAP_ROLE = 'googleMapRole'
module.exports.TIMEPICKER_ROLE = 'timePickerRole'
module.exports.CHECKBOX_GROUP_ROLE = 'checkboxGroupRole'
module.exports.STYLABLE_BUTTON_ROLE = 'stylableButtonRole'
module.exports.PROGRESS_BAR_ROLE = 'progressBarRole'
module.exports.VECTOR_IMAGE_ROLE = 'vectorImageRole'
module.exports.SELECTION_TAGS_ROLE = 'selectionTagsRole'
module.exports.SELECTION_TAGS_OPTIONS_ROLE = 'selectionTagsOptionsRole'
module.exports.STRIP_ROLE = 'stripRole'
module.exports.SIGNATURE_INPUT_ROLE = 'signatureInputRole'
